/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}
.ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.32), 9px 0 28px 0 rgba(0, 0, 0, 0.2), 12px 0 48px 16px rgba(0, 0, 0, 0.12);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.32), 0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.32), 0 -9px 28px 0 rgba(0, 0, 0, 0.2), 0 -12px 48px 16px rgba(0, 0, 0, 0.12);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #1f1f1f;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(255, 255, 255, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(255, 255, 255, 0.85);
  background: #1f1f1f;
  border-bottom: 1px solid #303030;
  border-radius: 2px 2px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(255, 255, 255, 0.85);
  background: #1f1f1f;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 10px;
  border-top: 1px solid #303030;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}
.ant-drawer .ant-picker-clear {
  background: #1f1f1f;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer .ant-picker-clear,
.ant-drawer .ant-slider-handle,
.ant-drawer .ant-anchor-wrapper,
.ant-drawer .ant-collapse-content,
.ant-drawer .ant-timeline-item-head,
.ant-drawer .ant-card {
  background-color: #1f1f1f;
}
.ant-drawer .ant-transfer-list-header {
  background: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.ant-drawer tr.ant-table-expanded-row > td,
.ant-drawer tr.ant-table-expanded-row:hover > td {
  background: #272727;
}
.ant-drawer .ant-table.ant-table-small thead > tr > th {
  background-color: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table {
  background-color: #1f1f1f;
}
.ant-drawer .ant-table .ant-table-row-expand-icon {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table tfoot > tr > th,
.ant-drawer .ant-table tfoot > tr > td {
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table thead > tr > th {
  background-color: #272727;
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table tbody > tr > td {
  border-bottom: 1px solid #3a3a3a;
}
.ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-left,
.ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-right {
  background-color: #1f1f1f;
}
.ant-drawer .ant-table tbody > tr.ant-table-row:hover > td {
  background: #303030;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered thead > tr > th,
.ant-drawer .ant-table.ant-table-bordered tbody > tr > td,
.ant-drawer .ant-table.ant-table-bordered tfoot > tr > th,
.ant-drawer .ant-table.ant-table-bordered tfoot > tr > td {
  border-right: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #303030;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-container {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
  border-right: 1px solid #3a3a3a;
}
.ant-drawer .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #3a3a3a;
}
.ant-drawer .ant-table .ant-table-filter-trigger-container-open {
  background-color: #525252;
}
.ant-drawer .ant-picker-calendar-full {
  background-color: #1f1f1f;
}
.ant-drawer .ant-picker-calendar-full .ant-picker-panel {
  background-color: #1f1f1f;
}
.ant-drawer .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 2px solid #3a3a3a;
}
.ant-drawer .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
}
.ant-drawer .ant-badge-count {
  box-shadow: 0 0 0 1px #1f1f1f;
}
.ant-drawer .ant-tree-show-line .ant-tree-switcher {
  background: #1f1f1f;
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}
